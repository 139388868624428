import React from "react";
import PropTypes from "prop-types";
import "./ComplexityTooltip.css";

export const ComplexityTooltip = (props) => {

  let path = window.location.pathname;
  
  let toolTipClass = "";

  if (path === "/apply-reset-password") {
    toolTipClass = "apply-reset-password"
  } else if (path === "/apply-now") {
    toolTipClass = "apply-now-password"
  } else if (path === "/"){
    toolTipClass = "an-button-password"
  }
  
  const determineValid = (regex) => {
    let regexConvert = new RegExp(regex, "gm");
    if (props.string.match(regexConvert)) {
      return "valid";
    } else {
      return "";
    }
  };

  return (
    props.visible && (
      <div id="complexity-tooltip" className={toolTipClass}>
        <p>Password must contain at least</p>
        {(props.data || []).map((requirement, index) => {
          if (requirement.min > 0) {
            return (
              <div 
                key={requirement.name + index}
                className={'complexityRule' + ' ' + determineValid(requirement.pattern)}
              >
                <span className={determineValid(requirement.pattern)}></span>
                {requirement.name}
              </div>
            );
          }
        })}
      </div>
    )
  );
};

ComplexityTooltip.propTypes = {
  data: PropTypes.array.isRequired,
  string: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};
