import React, { FC, useEffect, useRef, useState } from "react";
import {
  Typography,
  ThemeProvider as MuiThemeProvider,
  Stack,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme, TextInput } from "../habitat";
import { SsoButtonGroup } from "./SsoButtonGroup";
import AuthenticationLogin from "../assets/Network/POST/AuthenticationLogin";
import AuthenticationRegister from "../assets/Network/POST/AuthenticationRegister";
import CheckForMailErrors from "../assets/Network/GET/CheckForMailErrors";
import { SsoOr } from "./SsoOr";
import { LoginErrorMessage } from "../assets/Components/Login/LoginErrorMessage";
import {
  getPasswordRequirements,
  validatePassword,
} from "../assets/Components/Login/passwordRequirements";
import { isFloifyEmail } from "../assets/utils/isFloifyEmail";
import { ComplexityTooltip } from "../assets/Components/ComplexityTooltip/ComplexityTooltip";
import { AnButton } from "../assets/Components/Login/AnButton";
import { AnLink } from "../assets/Components/Login/AnLink";
import { Box } from "@mui/system";
import { MicrosoftButton } from "./MicrosoftButton";
import { checkSSOEnabledForUser } from "./SsoApi";

interface SsoLoginCardProps {
  titleText?: string;
  isCard?: boolean;
  isRegistration?: boolean;
  companySsoProviders?: Array<any>;
  primaryColor?: string;
  applyNowEnabled: boolean;
}

interface SsoLoginFormState {
  mail: string;
  confirmEmail?: string;
  password: string;
}

const ExistingAccount = styled("div")`
  padding: 0 32px;
  text-align: center;
`;

export const SsoLoginCard = ({
  titleText = "Welcome back",
  isCard = true,
  isRegistration = false,
  companySsoProviders = [],
  primaryColor = theme.palette.primary[700],
  applyNowEnabled,
}: SsoLoginCardProps) => {
  const confirmReference = useRef<HTMLInputElement>();
  const passwordReference = useRef<HTMLInputElement>();
  const [emailValidated, setEmailValidated] = useState<boolean>(
    !isRegistration
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordRequirements, setPasswordRequirements] = useState<any>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [registerErrorMessage, setRegisterErrorMessage] = useState<string>("");
  const [showHideVisible, setShowHideVisible] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordComplexityVisible, setPasswordComplexityVisible] =
    useState<boolean>(false);

  const [formState, setFormState] = useState<SsoLoginFormState>({
    mail: "",
    confirmEmail: "",
    password: "",
  });
  const [isSSOEmail, setIsSSOEmail] = useState<boolean>(false);
  const [isApplyNowPage, setIsApplyNowPage] = useState<boolean>(false);
  const isSsoEnabledForSubdomains = window?.PARAMS.ssoRequiredForSubdomains;

  const [loginParams] = useState<any>(window.LOGIN_PARAMS);
  const [authenticityToken, setAuthenticityToken] = useState<string>("");

  const trimmedEmail = formState.mail?.trim();
  useEffect(() => {
    checkIsApplyNowPage();
    const initializePasswordRequirements = async () => {
      const requirements = await getPasswordRequirements();
      setPasswordRequirements(requirements);
    };

    setAuthenticityToken(window.AN2_CSRF);
    initializePasswordRequirements();
  }, []);

  useEffect(() => {
    if (emailValidated && isRegistration) {
      confirmReference?.current?.focus();
    } else if (emailValidated) {
      passwordReference?.current?.focus();
    }
  }, [emailValidated]);

  useEffect(() => {
    setShowHideVisible(formState?.password?.length > 0);
  }, [formState.password]);

  useEffect(() => {
    setPasswordComplexityVisible(formState?.password?.length > 0);
  }, [formState.password]);

  useEffect(() => {
    if (isSsoEnabledForSubdomains) {
      const isValidemail = (valueToTest) => {
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,3}))$/;
        if (
          valueToTest !== "" &&
          !re.test(valueToTest) &&
          valueToTest.length !== 0
        ) {
          return false;
        } else if (valueToTest === "") {
          return false;
        } else {
          return true;
        }
      };
      // check email input for keyup event
      let emailValue = formState.mail;

      if (isValidemail(emailValue)) {
        const payload = new FormData();
        payload.append("authenticityToken", authenticityToken);

        checkSSOEnabledForUser(emailValue, payload).then((res) => {
          if (res === true) {
            setIsSSOEmail(true);
          } else {
            setIsSSOEmail(false);
          }
        });
      }
    }
  }, [formState.mail]);

  const checkIsApplyNowPage = () => {
    setIsApplyNowPage(window.location.pathname === "/apply-now");
  };

  const onStartRegistration = async (event) => {
    event.preventDefault();

    validateEmail(trimmedEmail);
  };

  const onFinishRegistration = async (event) => {
    event.preventDefault();

    const validConfirmation = validateConfirmation();
    const validPassword = validatePassword(
      formState.password,
      passwordRequirements
    );

    setPasswordError(!validPassword);

    if (validConfirmation && validPassword && !submitting) {
      setSubmitting(true);

      try {
        await AuthenticationRegister(trimmedEmail, formState.password);

        window.location.href = `/n/an3/${window.location.search}`;
      } catch (error) {
        setSubmitting(false);
        setRegisterErrorMessage(error?.response?.data?.message);
      }
    }
  };

  const validateConfirmation = () => {
    const valid = trimmedEmail === formState.confirmEmail?.trim();

    if (!valid) {
      setErrorMessage("Please make sure your email addresses match.");
    } else {
      setErrorMessage("");
    }

    return valid;
  };

  const validateEmail = async (email) => {
    // returns true if email is floify.com email
    if (isFloifyEmail(email)) {
      setEmailValidated(false);
      setErrorMessage(
        "You cannot use a floify.com email address. Please use a different email address."
      );
    } else {
      try {
        const response = await CheckForMailErrors(email);
        let newErrorMessage: typeof errorMessage = undefined;

        switch (response) {
          case "adminAccount":
          case "mailExists":
          case "noPasswordAppSubmitted":
          case "verifyMailSent":
            newErrorMessage =
              'An account with that email address already exists. Click "Log In" instead.';
            break;

          case "lenderAccount":
            newErrorMessage =
              "This email belongs to a lender in Floify and cannot be reused. Enter a different email to continue.";
            break;
          case "emptyMail":
            newErrorMessage = "Email is required";
            break;
          case "illegalMail":
            newErrorMessage = "Invalid Email";
            break;
        }

        setEmailValidated(!newErrorMessage);
        setErrorMessage(newErrorMessage);
      } catch (error) {
        console.log(error?.response?.data);
      }
    }
  };

  const onLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await AuthenticationLogin(
        trimmedEmail,
        formState.password
      );

      if (loginParams?.forward) {
        window.location.href = loginParams.forward;
      } else {
        window.location.href = response?.data?.uri || window.location.href;
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <MuiThemeProvider theme={theme}>
      {/* if we want to display the content as a card have a boxshadow, otherwise its flat */}
      <Card
        sx={{
          p: 4,
          maxWidth: 343,
          mb: 3,
          ml: "auto",
          mr: "auto",
        }}
        elevation={isCard ? 3 : 0}
      >
        <Typography
          variant="h1"
          sx={{
            color: theme.palette.grey[900],
            mb: 3,
            margin: "0px 0px 8px",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "24px",
            fontFamily: "proxima-nova !important",
          }}
        >
          {isRegistration ? "Start a loan application" : titleText}
        </Typography>

        <Typography
          variant="caption"
          component="p"
          sx={{ color: theme.palette.grey[700], mb: 3, fontSize: "14px" }}
        >
          {isRegistration
            ? "Beginning the loan application process? You’re in the right place! Get started by creating an account."
            : "Log in with your account."}
        </Typography>

        {companySsoProviders &&
          companySsoProviders.length > 0 &&
          !isSSOEmail && (
            <>
              <SsoButtonGroup
                companySsoProviders={companySsoProviders}
                type={isRegistration ? "register" : "login"}
              />
              <SsoOr />
            </>
          )}

        {registerErrorMessage && (
          <LoginErrorMessage marginTop={28}>
            {registerErrorMessage}
          </LoginErrorMessage>
        )}
        <form>
          <Stack>
            <TextInput
              disabled={emailValidated && isRegistration}
              label="Email"
              sx={{ mr: 2, mb: 1 }}
              lblsx={{ fontSize: "14px", fontWeight: 600 }}
              fullWidth={true}
              inputProps={{
                id: "mail",
                role: "textbox",
                "aria-label": "Email Address",
              }}
              name="mail"
              value={trimmedEmail}
              onChange={handleInputChange}
              primaryColor={primaryColor}
              error={
                !!errorMessage &&
                errorMessage !== "Please make sure your email addresses match."
              }
            />

            {errorMessage &&
              errorMessage !==
                "Please make sure your email addresses match." && (
                <LoginErrorMessage marginTop={0}>
                  {errorMessage}
                </LoginErrorMessage>
              )}

            {emailValidated && (
              <>
                {isRegistration && (
                  <TextInput
                    inputRef={confirmReference}
                    label="Confirm Email"
                    sx={{ mr: 2, mb: 1 }}
                    lblsx={{ fontSize: "14px", fontWeight: 600 }}
                    fullWidth={true}
                    inputProps={{
                      id: "confirmEmail",
                      role: "textbox",
                      "aria-label": "Confirm Email",
                    }}
                    name="confirmEmail"
                    value={formState.confirmEmail}
                    onChange={handleInputChange}
                    primaryColor={primaryColor}
                    error={
                      errorMessage ===
                      "Please make sure your email addresses match."
                    }
                  />
                )}

                {errorMessage ===
                  "Please make sure your email addresses match." && (
                  <LoginErrorMessage marginTop={0}>
                    {errorMessage}
                  </LoginErrorMessage>
                )}

                {!isSSOEmail && (
                  <Box sx={{ position: "relative" }}>
                    <TextInput
                      fcsx={{ width: "100%" }}
                      inputRef={passwordReference}
                      type={passwordVisible ? "text" : "password"}
                      label="Password"
                      sx={{ mr: 2, mb: 2 }}
                      lblsx={{ fontSize: "14px", fontWeight: 600 }}
                      fullWidth={true}
                      inputProps={{
                        id: "password",
                        role: "textbox",
                        "aria-label": "Input Password",
                      }}
                      name="password"
                      value={formState.password}
                      onChange={handleInputChange}
                      onBlur={() => setPasswordComplexityVisible(false)}
                      primaryColor={primaryColor}
                      error={!!passwordError}
                    />

                    {passwordError && (
                      <LoginErrorMessage marginTop={0}>
                        Please enter a valid password.
                      </LoginErrorMessage>
                    )}

                    <ComplexityTooltip
                      string={formState.password}
                      visible={isRegistration && passwordComplexityVisible}
                      data={passwordRequirements}
                    />

                    <ShowHide
                      visible={showHideVisible}
                      showing={passwordVisible}
                      setShowing={setPasswordVisible}
                    />
                  </Box>
                )}
              </>
            )}

            {isSSOEmail ? (
              <Box sx={{ mt: 1 }}>
                <MicrosoftButton mail={trimmedEmail} />
              </Box>
            ) : (
              <AnButton
                sx={{
                  mb: 2,
                }}
                applyNowColor={primaryColor}
                type="submit"
                size="large"
                variant="contained"
                disableRipple={true}
                onClick={
                  isRegistration && emailValidated
                    ? onFinishRegistration
                    : isRegistration
                    ? onStartRegistration
                    : onLogin
                }
              >
                {isApplyNowPage ? "Continue with email" : "Log In"}
              </AnButton>
            )}

            {!isRegistration && !isSSOEmail && (
              <>
                <AnLink
                  variant="body2"
                  href="/apply-forgot-password"
                  sx={{
                    color: `${primaryColor} !important`,
                    mb: 2,
                  }}
                >
                  Forgot password?
                </AnLink>

                {applyNowEnabled && (
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.grey[900] }}
                  >
                    Don't have an account?{" "}
                    <AnLink
                      sx={{ color: `${primaryColor} !important` }}
                      href="/apply-now"
                    >
                      Apply Now
                    </AnLink>
                  </Typography>
                )}
              </>
            )}

            {isRegistration && (
              <div>
                By signing in, you agree to the{" "}
                <AnLink
                  applyNowColor={primaryColor}
                  href="https://floify.com/terms"
                  target="_blank"
                >
                  Terms of Use and Privacy Policy
                </AnLink>
              </div>
            )}
          </Stack>
        </form>
      </Card>

      {isRegistration && (
        <ExistingAccount>
          Have an account?{" "}
          <AnLink href="/login" applyNowColor={primaryColor}>
            Log in
          </AnLink>
        </ExistingAccount>
      )}
    </MuiThemeProvider>
  );
};

const ShowHideContainer = styled("div")`
  cursor: pointer;
  user-select: none;

  position: absolute;
  right: 3px;
  top: 35px;
  width: 44px;

  font-family: "SFProDisplay", Helvetica, Arial, sans-serif;
  font-size: 10px;
  line-height: 19px;
  text-align: center;

  color: rgb(3, 68, 105);
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
`;

type ShowHideProps = {
  visible: boolean;
  showing: boolean;
  setShowing: (showing: boolean) => void;
};

const ShowHide: FC<ShowHideProps> = ({ visible, showing, setShowing }) => {
  return (
    <ShowHideContainer
      style={{ display: visible ? "inline-block" : "none" }}
      onClick={() => setShowing(!showing)}
    >
      {showing ? "HIDE" : "SHOW"}
    </ShowHideContainer>
  );
};
