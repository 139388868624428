import MinimumPasswordReqs from "../../Network/GET/MinimumPasswordReqs";

export const getPasswordRequirements = async () => {
  const {
    passwordMinLength,
    passwordMinLowerChars,
    passwordMinNumbers,
    passwordMinSymbols,
    passwordMinUpperChars,
  } = await MinimumPasswordReqs();

  return [
    {
      name: `${passwordMinUpperChars} uppercase letter`,
      pattern: `^(.*[A-Z]){${passwordMinUpperChars},}.*$`,
      min: `${passwordMinUpperChars}`,
    },
    {
      name: `${passwordMinLength} characters`,
      pattern: `.{${passwordMinLength}}`,
      min: passwordMinLength,
    },

    {
      name: `${passwordMinLowerChars} lowercase letter`,
      pattern: `^(.*[a-z]){${passwordMinLowerChars},}.*$`,
      min: passwordMinLowerChars,
    },
    {
      name: `${passwordMinNumbers} number`,
      pattern: `^(.*[0-9]){${passwordMinNumbers},}.*$`,
      min: passwordMinNumbers,
    },
    {
      name: `${passwordMinSymbols} special character (!, #, %, etc.)`,
      pattern: `^(.*[!@#$%^&*(),.?":{}\\[\\]\\|<>]){${passwordMinSymbols},}.*$`,
      min: passwordMinSymbols,
    },
  ];
};

export const validatePassword = (password, requirements) => {
  const valid = requirements
    // We filter to only check the requirements that are actually required
    .filter((requirement) => requirement.min > 0)
    // Then we make sure each requirement is fulfilled
    .every((requirement) =>
      new RegExp(requirement.pattern, "g").test(password)
    );

  return valid;
};
